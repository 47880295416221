import huaren_icon from '@/assets/image/huaren_icon.jpg'
import all_icon from '@/assets/image/all_icon.jpg'
import liyue_icon1 from '@/assets/image/liyue_icon1.jpg'
import liyue_icon2 from '@/assets/image/liyue_icon2.jpg'

export const GRIDS = [
  {
    name: '第一网格',
    path: [
      [121.528462, 31.102786],
      [121.53123, 31.10398],
      [121.534191, 31.105266],
      [121.537817, 31.098973],
      [121.539954, 31.095004],
      [121.54137, 31.091458],
      [121.533624, 31.089474],
      [121.532337, 31.09324],
      [121.531478, 31.095133],
      [121.529526, 31.098936],
      [121.528462, 31.102786]
    ]
  }
]

export const MARKERS_PIC = [
  {
    position: [121.530823, 31.092825],
    icon: huaren_icon
  },
  {
    position: [121.540432, 31.09194],
    icon: all_icon
  },
  {
    position: [121.532427, 31.095937],
    icon: liyue_icon1
  },
  {
    position: [121.533682, 31.096562],
    icon: liyue_icon2
  }
]
