<template>
  <div class="map whole-w-h">
    <div class="map-container whole-w-h" id="container" tabindex="0"></div>
    <div class="map-wrap">
      <el-amap
        id="amap"
        ref="amap"
        :zoom="15.5"
        :center="[121.533921, 31.098038]"
        mapStyle="amap://styles/a85d17d6290c707c8f65239bfe84033f">
        <div v-if="videoMarkers.length">
          <el-amap-marker
            v-for="(item, idx) in videoMarkers"
            :key="`mk${idx}`"
            :offset="[-25, -10]"
            :position="item.position">
            <img
              :src="Camera"
              :style="{ height: '36px', width: '36px' }"
              @click="videoDialogFun(item.vedioUrl)" />
          </el-amap-marker>
        </div>
        <!-- <el-amap-marker
          v-for="(item, idx) in MARKERS_PIC"
          :key="`pic${idx}`"
          :offset="[-18, -36]"
          :position="item.position">
          <el-image
            :src="Camera"
            fit="cover"
            :preview-src-list="[item.icon]"
            :style="{ height: '36px', width: '36px', borderRadius: '18px' }" />
        </el-amap-marker> -->
        <el-amap-polygon
          v-for="(item, idx) in GRIDS"
          :key="`dt${idx}`"
          :path="item.path"
          :vid="idx"
          :fill-opacity="0"
          :stroke-weight="3"
          stroke-color="#a0522d"
          :stroke-opacity="1" />
      </el-amap>
    </div>
    <!-- 视频播放弹窗 -->
    <el-dialog
      title="实时视频"
      :visible.sync="videoDialogVisible"
      width="1024px"
      :append-to-body="true"
      custom-class="common-dialog"
      @closed="closeDialog">
      <div class="video-box" id="video-wrap" :style="{ height: '500px' }" />
    </el-dialog>
  </div>
</template>
<script>
import { GRIDS, MARKERS_PIC } from './data'
import Camera from '@/assets/svg/camera.svg'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { videoResoureStart, videoResoureStop, fetchVideoList } from '@/api/video'
const VIDEO_URL = process.env.VUE_APP_VIDEO_URL

export default {
  data() {
    return {
      Camera,
      MARKERS_PIC,
      GRIDS,
      map: null,
      videoDialogVisible: false,
      myPlayer: null,
      videoUri: '',
      videoId: '',
      videoMarkers: []
    }
  },
  created() {
    this.getVideoList()
  },
  methods: {
    async getVideoList() {
      const res = await fetchVideoList()
      if (res.result == 0) {
        this.videoMarkers = res.data.map(item => ({
          ...item,
          position: item.gdCoordinate && item.gdCoordinate.split(',')
        }))
      }
    },
    videoDialogFun(rtsp) {
      this.videoDialogVisible = true
      this.$nextTick(() => {
        this.videoStart(rtsp)
      })
    },
    async videoStart(uri) {
      this.videoUri = uri
      let { running, uri: resUri, id } = await videoResoureStart({ uri })
      if (running) {
        let myVideoDiv = document.getElementById('video-wrap')
        if (myVideoDiv) {
          myVideoDiv.innerHTML =
            "<video id='myVideo' class='video-js vjs-default-skin  vjs-big-play-centered' controls preload='auto' style='width: 100%;height: 100%; object-fit: fill'><source style='width: 100%;height: 100%' type='application/x-mpegURL'></video>"
        }
        this.videoId = id
        this.$nextTick(() => {
          this.myPlayer = videojs(
            'myVideo',
            {
              bigPlayButton: true,
              textTrackDisplay: false,
              posterImage: false,
              errorDisplay: false,
              hls: {
                withCredentials: true
              }
            },
            () => {
              this.myPlayer.on('error', () => {
                this.myPlayer.dispose()
                this.videoStart(uri)
                console.log('refresh video')
              })
            }
          )
          this.myPlayer.src(`${VIDEO_URL}${resUri}`)
          this.myPlayer.play()
        })
      } else {
        this.$message.warning('sorry, 视频获取出错了:-(')
      }
    },
    async closeDialog() {
      await videoResoureStop(this.videoUri, this.videoId)
      this.myPlayer.dispose()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/style/variables.less');

.map {
  position: absolute;
  &-wrap {
    .position(fixed,50%,auto,50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    #amap {
      height: 100%;
      width: 100%;
    }
  }
}
::v-deep .amap-logo {
  display: none;
  opacity: 0 !important;
}

::v-deep .amap-copyright {
  opacity: 0;
}
</style>
