import request from '@/utils/request'
const VIDEO_URL = process.env.VUE_APP_VIDEO_URL

export const videoResoureStart = params => {
  return request({
    url: `${VIDEO_URL}/start`,
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const videoResoureStop = (uri, id) => {
  return request({
    url: `${VIDEO_URL}/stop`,
    method: 'POST',
    data: { uri, id },
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const fetchVideoList = () => {
  return request({
    url: `/video/listAll`,
    method: 'GET'
  })
}
