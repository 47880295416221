<template>
  <div class="layout">
    <div class="layout-header">
      <div class="header-side left">
        <div class="area">
          <!-- 天气 -->
          <span class="date-s mr-50">多云</span>
          <span>空气质量 优</span>
          <span class="time-s ml-50">PM2.5 12</span>
        </div>
        <!-- 一键切换主题 -->
      </div>
      <div class="title-wrap full-bg">
        <div class="title">
          <img src="@/assets/image/logo.jpg" alt="" />
          <span>和美勤劳基层治理平台</span>
        </div>
        <!-- <img src="@/assets/image/title-logo.png" /> -->
      </div>
      <div class="header-side right">
        <div class="area">
          <!-- 时间 -->
          <span class="date-s mr-50">{{ time.date }}</span>
          <span>{{ time.week }}</span>
          <span class="time-s ml-50">{{ time.time }}</span>
        </div>
      </div>
    </div>
    <div class="slider left" :class="{ slideIn: slideIn }">
      <router-view name="left" />
    </div>
    <div class="slider right" :class="{ slideIn: slideIn }">
      <router-view name="right" />
    </div>
    <ScopeMaps />
    <!-- <div class="layout-bottom full-bg">
      <div class="layout-bottom__wrap">
        <div v-for="(item, index) in PATH" :key="`lybtn${item.class}`" class="btn-item full-bg">
          <svg-icon :icon-class="item.class" />
          <label>{{ item.label }}</label>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Jieqi from '@/utils/jieqi'
import ScopeMaps from '@/components/Map/ScopeMaps'
const PATH = [
  {
    label: '党建',
    class: 'bottom-partybuild1',
    classActive: 'bottom-partybuild2',
    name: 'PartyBuildView'
  },
  {
    label: '人房',
    class: 'bottom-peoplehouse1',
    classActive: 'bottom-peoplehouse2',
    name: 'PeopleHouseView'
  },
  {
    label: '积分',
    class: 'bottom-point1',
    classActive: 'bottom-point2',
    name: 'PointView'
  },
  {
    label: '治理',
    class: 'bottom-gover1',
    classActive: 'bottom-gover2',
    name: 'GoverView'
  }
]
export default {
  components: {
    ScopeMaps
  },
  data() {
    return {
      iframeSrc: '',
      commCode: this.$store.state.currentCommCode,
      slideIn: false,
      PATH,
      time: {
        date: '',
        time: '00:00:00',
        week: '',
        calen: '',
        jieqi: ''
      },
      timer: null,
      themeValue: 'orange',
      options: [
        {
          btnText: '党政红',
          theme: 'orange'
        },
        {
          btnText: '党政蓝',
          theme: 'blue'
        }
      ],
      bottomIndex: 0
    }
  },
  created() {
    let theme = localStorage.getItem('themeMYT') || 'orange'
    document.documentElement.setAttribute('theme', theme)
    this.$store.dispatch('themeUI/CHANGE_THEME_ACTION', theme)
  },
  mounted() {
    setTimeout(() => {
      this.slideIn = true
    }, 250)
    this.timer = setInterval(() => {
      this.time = Jieqi.showTime()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    changeTheme() {
      document.documentElement.setAttribute('theme', this.themeValue)
      localStorage.setItem('themeMYT', this.themeValue)
      this.$store.dispatch('themeUI/CHANGE_THEME_ACTION', this.themeValue)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/style/variables.less');
@headerHeight: 128px;
.layout {
  display: grid;
  grid-template-columns: 30% 1fr 30%;
  background: #070707;
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url('@/assets/image/background.png') no-repeat;
  background-size: 100% 100%;
  .slider {
    position: relative;
    display: flex;
    left: -100%;
    transition: all 0.3s linear;
    height: calc(100vh - @headerHeight);
    margin-left: 10px;
    padding: 5px 0 50px 30px;
    box-sizing: border-box;
    z-index: 1;
    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 101%;
      .position(absolute,-2%,auto,0,auto,-1);
      background: url('@/assets/image/left-bor.png') no-repeat;
      background-size: 100% 100%;
      background-position: left center;
    }
    &.slideIn {
      left: 0;
    }
    &.right {
      grid-area: 2 / 3 / span 2 / span 1;
      left: unset;
      right: -100%;
      margin-left: unset;
      margin-right: 10px;
      padding: 5px 30px 50px 0;
      &.slideIn {
        right: 0;
      }
      &::before {
        left: auto;
        right: 0;
        transform: rotateY(180deg);
      }
    }
  }
  &-bottom {
    box-sizing: border-box;
    background-size: 100% 100%;
    .position(absolute,auto,-2px,50%);
    width: 100%;
    transform: translateX(-50%);
    padding: 0 3%;
    display: flex;
    justify-content: center;
    &::after {
      content: '';
      background: linear-gradient(to top, #94524c, rgba(112, 65, 51, 0.1));
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &__wrap {
      width: 45%;
      background-size: contain;
      background-position: center;
      aspect-ratio: 778 / 86;
      background-image: url('@/assets/image/bottom-bg.png');
      height: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0;
      padding: 0 2%;
    }
    .btn-item {
      aspect-ratio: 162 / 81;
      position: relative;
      .flex(row,center,center);
      gap: 8px;
      font-size: 22px;
      &.border {
        &::before {
          display: block;
          content: '';
          .position(absolute,50%,auto,auto,0);
          transform: translateY(-50%);
          width: 0;
          height: 70%;
          border: 1px solid transparent;
          border-image: linear-gradient(
              180deg,
              rgba(223, 133, 124, 0),
              rgba(255, 214, 209, 1),
              rgba(223, 137, 128, 0)
            )
            2 2;
        }
      }
      &.active {
        background: url('@/assets/image/bottom_item_active.png') no-repeat;
        background-size: 100% 100%;
        border-bottom: 1px solid #ffffff;
        &::before {
          border-image: none;
        }
        &::after {
          content: '';
          .position(absolute,auto,6px,50%);
          transform: translateX(-50%);
          width: 60px;
          height: 25px;
          background: url('@/assets/svg/layout/bottom_item_active2.svg') no-repeat;
          animation: iconflash 2s linear infinite;
        }
        label {
          color: var(--layoutBottomTextColorA);
        }
      }
      svg {
        width: 22px;
        height: 22px;
      }
      label {
        color: var(--layoutBottomTextColor);
      }
      .flag-area {
        .position(absolute,0,auto,0);
        transform: translateY(-140px);
        width: 100%;
        height: 130px;
        .flex(column,space-around,center);
        &.three {
          transform: translateY(-210px);
          height: 200px;
        }
        .flag {
          position: relative;
          width: 100%;
          height: 61px;
          line-height: 61px;
          font-size: 24px;
          letter-spacing: 2px;
          background-image: var(--layoutBottomTextColor2);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            .position(absolute,0,auto,0);
            background: url('@/assets/svg/layout/bottom_flag.svg') no-repeat;
          }
          &.activeFlag {
            &::before {
              background: url('@/assets/svg/layout/bottom_flag_active.svg') no-repeat;
            }
          }
        }
      }
    }
  }
  &-header {
    grid-area: auto / span 3;
    .flex(row,center,center);
    gap: 4%;
    height: @headerHeight;
    position: relative;
    background-image: url('@/assets/image/header-bg.svg');
    background-size: 100% 100%;
    aspect-ratio: 1920 / 128;
    width: 100%;
    z-index: 1;
    position: relative;
    &::before {
      content: '';
      background: linear-gradient(to bottom, #94524c 65%, rgba(112, 65, 51, 0.1));
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 78%;
      z-index: 0;
    }
    .title-wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      place-items: center;
      height: 100%;
      .title {
        font-size: 48px;
        font-weight: bold;
        line-height: 64px;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to bottom, #f07b78, #ffffff);
        letter-spacing: 5px;
        white-space: nowrap;
        user-select: none;
        padding-top: 24px;
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 45px;
          height: 58px;
        }
      }
      img {
        width: 39px;
        height: 39px;
      }
    }
    .header-side {
      height: 60px;
      aspect-ratio: 570/60;
      position: relative;
      &.right,
      &.left {
        font-size: 20px;
        font-weight: 400;
        color: var(--layoutTopTextColor2);
        line-height: 60px;
        letter-spacing: 1px;
        .select-box {
          margin-right: 20px;
          .flex(row,flex-end,flex-start);
        }
        .area {
          width: 100%;
          height: 100%;
          background: url('@/assets/image/header-side-left.png') no-repeat;
          background-size: 100% 100%;
          color: #ffffff;
          font-size: 14px;
        }
      }

      &.right {
        .area {
          background: url('@/assets/image/header-side-right.png') no-repeat;
        }
      }
    }
  }
}
@keyframes iconflash {
  0% {
    margin-bottom: 0;
  }
  25% {
    margin-bottom: 3px;
  }
  50% {
    margin-bottom: 0;
  }
  75% {
    margin-bottom: -3px;
  }
  100% {
    margin-bottom: 0;
  }
}
</style>
