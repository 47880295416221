/*







 * 指令用处： 复制







 * 指令说明： 点击实现







 * 指令实例：  v-clipboard:copy="text" 复制内容







 *            v-clipboard:success="function"  复制回调







 */

import clipboard from './el-clipboard' //复制

const install = Vue => {
  Vue.directive('clipboard', clipboard)
}

export default {
  install
}
