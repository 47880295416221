const themeUI = localStorage.getItem('themeMYT') || 'orange'

const state = {
  themeUI: themeUI
}

const mutations = {
  CHANGE_THEME: (state, themeUI) => {
    state.themeUI = themeUI
  }
}

const actions = {
  CHANGE_THEME_ACTION(context, themeUI) {
    context.commit('CHANGE_THEME', themeUI)
  }
}

const getters = {
  themeUI(state) {
    return state.themeUI
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
