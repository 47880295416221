import { login, getUserData } from '@/api/user'

const state = {}

const mutations = {}

const actions = {
  async login({ commit }, userInfo) {
    try {
      let loginRes = await login(userInfo)
      if (loginRes && loginRes.result == 0) {
        if (loginRes.data) {
          sessionStorage.setItem('saas-token', loginRes.data)
        }
        let userRes = await getUserData()
        sessionStorage.setItem('user-info', JSON.stringify(userRes.data))
        return userRes
      } else {
        return loginRes
      }
    } catch (e) {
      return { result: 1, msg: '网络错误' }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
