import router from './router'
const whiteList = ['/login'] // 路由白名单

router.beforeEach((to, from, next) => {
  const hasToken = sessionStorage.getItem('saas-token')
  if (hasToken) {
    next()
  } else if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    next('/login')
  }
})
