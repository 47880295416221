import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/CommonLayout'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login/loginIndex'),
    hidden: true
  },
  {
    path: '/center',
    redirect: '/partyBuild',
    component: Layout,
    children: [
      {
        path: '/partyBuild',
        name: 'PartyBuildView',
        components: {
          left: () => import('@/views/PartyBuild/PartyBuildViewLeft.vue'),
          right: () => import('@/views/PartyBuild/PartyBuildViewRight.vue')
        }
      },
      {
        path: '/peopleHouse',
        name: 'PeopleHouseView',
        components: {
          left: () => import('@/views/PartyBuild/PartyBuildViewLeft.vue'),
          right: () => import('@/views/PartyBuild/PartyBuildViewRight.vue')
        }
      },
      {
        path: '/point',
        name: 'PointView',
        components: {
          left: () => import('@/views/PartyBuild/PartyBuildViewLeft.vue'),
          right: () => import('@/views/PartyBuild/PartyBuildViewRight.vue')
        }
      },
      {
        path: '/gover',
        name: 'GoverView',
        components: {
          left: () => import('@/views/PartyBuild/PartyBuildViewLeft.vue'),
          right: () => import('@/views/PartyBuild/PartyBuildViewRight.vue')
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
