import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import dialogAction from '@/store/modules/dialogAction'
import mapAction from '@/store/modules/mapAction'
import themeUI from './modules/themeUI'
import user from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkVisiable: false,
    checkedCount: 0,
    currentCommCode: 'T0001'
  },
  getters,
  mutations: {},
  actions: {},
  modules: {
    dialogAction,
    mapAction,
    themeUI,
    user
  }
})
